<template>
<div>
  <card v-if="cardUser && cardRank">
    <div :class="[cardUser.poom_dan.name === 'Poom'? 'redBorder': 'blackBorder']">
      <div class="row">
        <div class="col-sm-6 align-content-center text-center">
          <label>Name</label>
          <h2>{{cardUser.first_name}} {{cardUser.last_name}}</h2>
        </div>
        <div class="col-sm-6 align-content-center text-center">
          <label>Current Rank</label>
          <h3 :class="[cardUser.poom_dan.name === 'Poom'? 'redText': null]">{{cardUser.poom_dan.name}} - {{cardRank.name}}</h3>
        </div>
<!--        <div class="col-sm-4 align-content-center text-center">
          <label>Points</label>
          <h3>{{currentPoints}} / {{totalPoints}}</h3>
        </div>-->
      </div>
      <div class="row">
        <div class="col-sm-4" v-for="column of Object.keys(categories)" v-bind:key="column">
          <div class="row" style="width: 100%" v-for="category of categories[column]" v-bind:key="category.id">
            <div class="col-sm-12" v-if="editable || category.items.length > 0">
              <h4 class="text-center">{{category.name}}</h4>
              <div class="item" v-for="item of category.items" v-bind:key="item.id">
                <div class="itemName">
                  {{item.name}}
                  <el-button v-if="editable && category.id !== 'breakings'" class="deleteButton" type="danger" icon="el-icon-delete" circle @click="deleteItem(item, category, column)"></el-button>
                </div>
                <hr>
              </div>
              <div class="text-center">
                <el-select filterable v-model="category.newItem" placeholder="" style="width: 100%; margin-bottom: 10px"
                           @change="addItem(category)" v-if="editable && category.dropdownOpen && category.items.length !== category.allItems.length"
                           @visible-change="handleVisibleChange($event, category)">
                  <el-option v-for="item in category.allItems.filter(item => !category.items.some(data => data.id === item.id))"
                             :key="item.id" :value="item.id" :label="item.name">
                  </el-option>
                </el-select>
                <el-button v-if="editable && !category.dropdownOpen && category.items.length !== category.allItems.length" class="addButton" icon="el-icon-plus" circle @click="openDropdown(category, column)"></el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br>
      <div class="text-center">
        <el-button v-if="editable" type="danger" @click="resetCard" icon="">Reset to Rank Default</el-button>
      </div>
    </div>
  </card>
  <el-dialog
    center
    title="Error"
    :visible.sync="modals.error">
    <div class="text-center">
      <span>Error: {{ error_txt }}</span>
      <br>
      <span slot="footer" class="dialog-footer">
          <el-button type="danger" @click="closeModal('error')" icon="">OK</el-button>
        </span>
    </div>
  </el-dialog>
  <el-dialog
    center
    title="Success"
    :visible.sync="modals.success">
    <div class="text-center">
      <span>{{ success_txt }}</span>
      <br>
      <span slot="footer" class="dialog-footer">
          <el-button type="success" @click="closeModal('success')" icon="">OK</el-button>
        </span>
    </div>
  </el-dialog>
</div>
</template>

<script>
import crypto from "crypto-js";
import { Dialog, Button, Input, InputNumber, TabPane, Tabs, Select, Option } from 'element-ui';
import {setTechniqueNames} from "/src/pages/Dashboard/Components/printing/setTechniqueNames";
import UserAPIService from "../../../../servicehandlers/UsersAPIService";
import RanksAPIService from "../../../../servicehandlers/RanksAPIService";
import TechniquesAPIService from "../../../../servicehandlers/TechniquesAPIService";
import KicksAPIService from "../../../../servicehandlers/KicksAPIService";
import KickCombosAPIService from "../../../../servicehandlers/KickCombosAPIService";
import PoomsaeAPIService from "../../../../servicehandlers/PoomsaeAPIService";
import SelfDefenseTechniquesAPIService from "../../../../servicehandlers/SelfDefenseTechniquesAPIService";
import CurriculumCardsAPIService from "../../../../servicehandlers/CurriculumCardsAPIService";
import StepSparringAPIService from "../../../../servicehandlers/StepSparringAPIService";
import BreakingTechniquesAPIService from "../../../../servicehandlers/BreakingTechniquesAPIService";
import OutlinesAPIService from "../../../../servicehandlers/OutlinesAPIService";
import SupportOptionsAPIService from "../../../../servicehandlers/SupportOptionsAPIService";
import GeneralsAPIService from "../../../../servicehandlers/GeneralsAPIService";

const usersAPIService = new UserAPIService();
const ranksAPIService = new RanksAPIService();
const techniqueAPIService = new TechniquesAPIService()
const kicksAPIService = new KicksAPIService()
const kickCombosAPIService = new KickCombosAPIService()
const poomsaesAPIService = new PoomsaeAPIService()
const selfDefencesAPIService = new SelfDefenseTechniquesAPIService()
const curriculumCardsAPIService = new CurriculumCardsAPIService()
const stepSparringAPIService = new StepSparringAPIService()
const breakingTechniquesAPIService =  new BreakingTechniquesAPIService()
const outlinesAPIService = new OutlinesAPIService()
const supportOptionsAPIService =  new SupportOptionsAPIService()
const generalsAPIService = new GeneralsAPIService()
export default {
  components: {
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    [Input.name]: Input,
    [InputNumber.name]: InputNumber,
    [TabPane.name]: TabPane,
    [Tabs.name]: Tabs,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  name: "CurriculumCard",
  data() {
    return {
      modals: {
        error: false,
        success: false,
      },
      error_txt: '',
      success_txt: '',
      currentUser: null,
      cardUser: null,
      cardRank: null,
      editable: false,
      categories: {
        column1: [
          {
            id: 'generals',
            name: 'General Requirements',
            items: [],
            dropdownOpen: false,
            allItems: [],
            newItem: null,
          },
          {
            id: 'kicks',
            name: 'Kicking',
            items: [],
            dropdownOpen: false,
            allItems: [],
            newItem: null,
          },
          {
            id: 'kick_combos',
            name: 'Kicking Combos',
            items: [],
            dropdownOpen: false,
            allItems: [],
            newItem: null,
          },
        ],
        column2: [
          {
            id: 'techniques',
            name: 'Techniques',
            items: [],
            dropdownOpen: false,
            allItems: [],
            newItem: null,
          },
          {
            id: 'poomsaes',
            name: 'Poomsae',
            items: [],
            dropdownOpen: false,
            allItems: [],
            newItem: null,
          },
        ],
        column3: [
          {
            id: 'self_defense_techniques',
            name: 'Self Defence',
            items: [],
            dropdownOpen: false,
            allItems: [],
            newItem: null,
          },
          {
            id: 'step_sparrings',
            name: 'Step Sparring',
            items: [],
            dropdownOpen: false,
            allItems: [],
            newItem: null,
          },
          {
            id: 'breakings',
            name: 'Breaking',
            items: [],
            dropdownOpen: false,
            allItems: [],
            newItem: null,
          },
        ],
      },
      currentPoints: 0,
      totalPoints: 0,
    }
  },
  async mounted(){
    //console.clear()
    this.getGenerals()
    this.getKicks()
    this.getKickCombos()
    this.getTechniques()
    this.getPoomsae()
    this.getSelfDefense()
    this.getSparrings()
    this.getBreakings()
    this.getSupportOptions()
    await this.getCurrentUser()
    await this.getCardRank()
  },
  methods: {
    getGenerals(){
      return generalsAPIService.getGenerals(this.$router)
        .then(response => {
          for (let column of Object.keys(this.categories)){
            for (let category of this.categories[column]){
              if (category.id === 'generals'){
                  category.allItems = response
              }
            }
          }
        })
    },
    getKicks(){
      return kicksAPIService.getKick(this.$router)
        .then(response => {
          for (let column of Object.keys(this.categories)){
            for (let category of this.categories[column]){
              if (category.id === 'kicks'){
                category.allItems = response
              }
            }
          }
        })
    },
    getKickCombos(){
      return kickCombosAPIService.getKickCombos(this.$router)
        .then(response => {
          for (let column of Object.keys(this.categories)){
            for (let category of this.categories[column]){
              if (category.id === 'kick_combos'){
                category.allItems = response
              }
            }
          }
        })
    },
    getTechniques(){
      return techniqueAPIService.getTechnique(this.$router)
        .then(response => {
          for (let column of Object.keys(this.categories)){
            for (let category of this.categories[column]){
              if (category.id === 'techniques'){
                category.allItems = response
              }
            }
          }
        })
    },
    getPoomsae(){
      return poomsaesAPIService.getPoomsae(this.$router)
        .then(response => {
          for (let column of Object.keys(this.categories)){
            for (let category of this.categories[column]){
              if (category.id === 'poomsaes'){
                category.allItems = response
              }
            }
          }
        })
    },
    getSelfDefense(){
      return selfDefencesAPIService.getSelfDefenseTechniques(this.$router)
        .then(response => {
          for (let column of Object.keys(this.categories)){
            for (let category of this.categories[column]){
              if (category.id === 'self_defense_techniques'){
                category.allItems = response
              }
            }
          }
        })
    },
    getSparrings(){
      return stepSparringAPIService.getStepSparrings(this.$router)
        .then(response => {
          for (let column of Object.keys(this.categories)){
            for (let category of this.categories[column]){
              if (category.id === 'step_sparrings'){
                category.allItems = response
              }
            }
          }
        })
    },
    getBreakings(){
      return breakingTechniquesAPIService.getBreakingTechniques(this.$router)
        .then(response => {
          for (let column of Object.keys(this.categories)){
            for (let category of this.categories[column]){
              if (category.id === 'breakings'){
                category.allItems = response
              }
            }
          }
        })
    },
    getSupportOptions() {
      return supportOptionsAPIService.getSupportOptions(this.$router)
        .then((modifiers) => {
          this.dropdown_support_options = []
          for (const modifier of modifiers) {
            modifier.text = modifier.description;
            modifier.value = modifier.id;
            this.dropdown_support_options.push(modifier);
          }
        })
        .catch((error) => {
          this.error_txt = 'Error getting modifiers for dropdown: ' + error;
          this.openModal('error');
        });
    },
    getCurrentUser(){
      let userID = this.$store.getters.userId
      return usersAPIService.getUserById(userID, this.$router)
        .then(async (response) => {
          const birthday = new Date(crypto.AES.decrypt(response.birthday, process.env.VUE_APP_SECRET_KEY).toString(crypto.enc.Utf8))
          const birthDate = new Date(birthday);
          const today = new Date();
          let age = today.getFullYear() - birthDate.getFullYear();
          const monthDifference = today.getMonth() - birthDate.getMonth();
          if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
            age--;
          }
          response.age = age
          this.currentUser = response
          let scopes = this.$store.getters.scopes
          if (scopes.some(data => data.name === 'edit-all-events') && this.$route.params.userId){
            this.editable = true
            this.cardUser = await this.getUserById(this.$route.params.userId)
          } else {
            this.cardUser = this.currentUser
          }
        })
        .catch((e) => {
          this.error_txt = 'Error getting current user: ' + e
          this.openModal('error')
        })
    },
    getUserById(id){
      if (id) {
        return usersAPIService.getUserById(id, this.$router)
          .then(response => {
            return response
          })
          .catch((e) => {
            this.error_txt = 'Error getting card user: ' + e
            this.openModal('error')
          })
      } else {
        this.error_txt = 'No user selected'
        this.openModal('error')
      }
    },
    getCardRank(){
      return ranksAPIService.getRanksById(this.cardUser.rank_id, this.$router)
        .then(response => {
          this.cardRank = response
          this.getRankDetails()
        })
        .catch((e) => {
          this.error_txt = 'Error getting card rank: ' + e
          this.openModal('error')
        })
    },
    async getRankDetails(){
      try {
        this.cardRank.techniques = await techniqueAPIService.getTechniqueByRank(this.cardRank.id, this.$router)
        this.cardRank.kicks = await kicksAPIService.getKickByRank(this.cardRank.id, this.$router)
        this.cardRank.kick_combos = await kickCombosAPIService.getKickCombosByRank(this.cardRank.id, this.$router)
        this.cardRank.poomsaes = await poomsaesAPIService.getPoomsaeByRank(this.cardRank.id, this.$router)
        this.cardRank.self_defense_techniques = await selfDefencesAPIService.getSelfDefenseTechniquesByRank(this.cardRank.id, this.$router)
        this.cardRank.generals = await generalsAPIService.getGeneralsByRank(this.cardRank.id, this.$router)
        this.cardRank.outline = await outlinesAPIService.getDefaultOutlineDetailsByRankId(this.cardRank.id, this.$router)
        this.setTechniqueNames(this.cardRank.outline)
        this.getCurriculumCard()
      } catch (e) {
        this.error_txt = 'Error getting rank details: ' + e
        this.openModal('error')
      }
    },
    getCurriculumCard(){
      return curriculumCardsAPIService.getCurriculumCardByUserId(this.cardUser.id, this.$router)
        .then(response => {
          if (!response.success || response.rank_id !== this.cardRank.id){
            let columns = Object.keys(this.categories)
            for (let column of columns){
              for (let category of this.categories[column]){
                if (this.cardRank[category.id] && this.cardRank[category.id].length > 0){
                  for (let item of this.cardRank[category.id]){
                    let itemPoomDanId = item[category.id.slice(0, -1)].poom_dan_id
                    if (itemPoomDanId === 3 || itemPoomDanId === this.cardUser.poom_dan_id)
                    category.items.push(item[category.id.slice(0, -1)])
                  }
                } else{
                  if (category.id === 'step_sparrings'){
                    for (let sparring of category.allItems){
                      if (sparring.current){
                        category.items.push(sparring)
                      }
                    }
                  }
                  else if (category.id === 'breakings'){
                    for (let tech of this.cardRank.outline.breaking_techniques_to_outlines){
                      //console.log(tech)
                    }
                  }
                  else {
                    //console.log(category.id)
                  }
                }
              }
            }
            this.createOrUpdateCurriculumCard()
          } else {
            let columns = Object.keys(this.categories)
            for (let column of columns){
              for (let category of this.categories[column]){
                if (response[category.id] && response[category.id].length > 0){
                  for (let item of response[category.id]){
                    category.items.push(item)
                  }
                }
              }
            }
          }
        })
    },
    resetCard(){
      return curriculumCardsAPIService.deleteByUserID(this.cardUser.id, this.$router)
        .then(() => {
          this.categories = {
            column1: [
              {
                id: 'generals',
                name: 'General Requirements',
                items: [],
                dropdownOpen: false,
                allItems: [],
                newItem: null,
              },
              {
                id: 'kicks',
                name: 'Kicking',
                items: [],
                dropdownOpen: false,
                allItems: [],
                newItem: null,
              },
              {
                id: 'kick_combos',
                name: 'Kicking Combos',
                items: [],
                dropdownOpen: false,
                allItems: [],
                newItem: null,
              },
            ],
            column2: [
              {
                id: 'techniques',
                name: 'Techniques',
                items: [],
                dropdownOpen: false,
                allItems: [],
                newItem: null,
              },
              {
                id: 'poomsaes',
                name: 'Poomsae',
                items: [],
                dropdownOpen: false,
                allItems: [],
                newItem: null,
              },
            ],
            column3: [
              {
                id: 'self_defense_techniques',
                name: 'Self Defence',
                items: [],
                dropdownOpen: false,
                allItems: [],
                newItem: null,
              },
              {
                id: 'step_sparrings',
                name: 'Step Sparring',
                items: [],
                dropdownOpen: false,
                allItems: [],
                newItem: null,
              },
              {
                id: 'breakings',
                name: 'Breaking',
                items: [],
                dropdownOpen: false,
                allItems: [],
                newItem: null,
              },
            ],
          }
          this.getCurriculumCard()
        })
    },
    deleteItem(item, category){
      let index = category.items.indexOf(item)
      category.items.splice(index, 1)
      category.dropdownOpen = false
      this.createOrUpdateCurriculumCard()
    },
    handleVisibleChange(isVisible, category) {
      if (!isVisible) {
        this.closeDropdown(category);
      }
    },
    openDropdown(category){
      category.dropdownOpen = true
    },
    closeDropdown(category){
      category.dropdownOpen = false
    },
    addItem(category){
      let newItem = category.allItems.find(data => data.id === category.newItem)
      category.items.push(newItem)
      category.newItem = null
      category.dropdownOpen = false
      this.createOrUpdateCurriculumCard()
    },
    createOrUpdateCurriculumCard(){
      let temp = {
        user_id: this.cardUser.id,
        rank_id: this.cardRank.id,
      }
      for (let column of Object.keys(this.categories)){
        for (let category of this.categories[column]){
          let tapeName = category.id
          temp[tapeName] = []
          for (let item of category.items){
            let tempItem = {}
            tempItem[tapeName.slice(0, -1) + '_id'] = item.id
            temp[tapeName].push(tempItem)
          }
        }
      }
      return curriculumCardsAPIService.createOrUpdate(temp, this.$router)
        .then(response => {
          //this.getCurriculumCard()
        })
        .catch(e => {
          this.error_txt = 'Error while updating or creating curriculum card: ' + e
          this.openModal('error')
        })
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    setTechniqueNames,
  },
}
</script>

<style scoped>
h2, h3{
  margin: 0;
}
.redText{
  color: rgb(215, 31, 39);
}
.redBorder{
  border: rgb(215, 31, 39) 3px solid;
  padding: 10px;
}
.blackBorder{
  border: black 3px solid;
  padding: 10px;
}
.complete{
  color: #1e7e34;
}
.incomplete{
  color: rgb(215, 31, 39);
}
.item{
  padding: 0 5px;
  position: relative;
}
.itemName{
  padding: 0 10px;
}
hr{
  padding: 0;
  margin: 8px 0;
}
.deleteButton{
  position: absolute;
  right: 0;
  height: 30px;
  width: 30px;
  padding: 0;
  margin: 0;
}
.addButton{
  height: 30px;
  width: 30px;
  padding: 0;
  margin: 0;
}
</style>
